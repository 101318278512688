import React from "react";
import MainLayout from "../components/layoutmain";

/**
 * Page d'erreur 404
 * @returns {JSX.Element}
 * @constructor
 */
export default function NotFound() {
  const seoProps = {
    title: "Numeko - Notre approche",
    description: "Échanges, Idéation & Conception, Découvrez nos expertises",
  };

  return (
    <MainLayout seoProps={seoProps}>
      <h1>Page Not Found</h1>
      <p>Oops, we couldn't find this page!</p>
    </MainLayout>
  );
}
